
var $body,
    $header,
    $headerIcon,
    $menu,
    $toggleMenu,
    $slider,
    headerCollapseHeight = 30;


$(document).ready(function($) {

    console.log('%cLinku Novio',"font-size: 2em; font-family: sans-serif; color: white; background-color:#18aaff; padding:4px 8px; border-radius: 4px");
    $header = $('.js-header');
    $headerIcon = $('.js-wheel-icon');
    $body = $('body');
    $toggleMenu = $(".js-hamburger");
    $menu = $(".js-menu");
    $slider = $('.js-slider');


    $toggleMenu.click(function () {
        $(this).toggleClass('is-open');
        $menu.toggleClass('is-open');
        $body.toggleClass('c-menu__mobile-menu-is-open');
    })

    $(window).on('scroll', function () {


        if ($(this).scrollTop() >= headerCollapseHeight && !$header.hasClass('is_scrolled')) {
            console.log("scroll groter", $(this).scrollTop());
            $header.addClass('is_scrolled');
            $headerIcon.addClass('is_scrolled');
        } else if($(this).scrollTop() < headerCollapseHeight && $header.hasClass('is_scrolled')) {
            console.log("scroll kleiner", $(this).scrollTop());
            $header.removeClass('is_scrolled');
            $headerIcon.removeClass('is_scrolled');
        }
    });

    $slider.each(function (index, value) {
        let slider = tns({
            container: value,
            items: 1,
            mouseDrag: true,
            speed: 600,
            nav: true,
            navPosition: "bottom",
            controlsText: ['<i class="fal fa-chevron-left"></i>', '<i class="fal fa-chevron-right"></i>'],
            controls: true,
            autoHeight: false,
        });
    });
});
